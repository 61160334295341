// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bio-tsx": () => import("./../../../src/pages/bio.tsx" /* webpackChunkName: "component---src-pages-bio-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-music-tsx": () => import("./../../../src/pages/music.tsx" /* webpackChunkName: "component---src-pages-music-tsx" */),
  "component---src-pages-teaching-tsx": () => import("./../../../src/pages/teaching.tsx" /* webpackChunkName: "component---src-pages-teaching-tsx" */),
  "component---src-pages-theater-tsx": () => import("./../../../src/pages/theater.tsx" /* webpackChunkName: "component---src-pages-theater-tsx" */)
}

